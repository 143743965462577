import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, FormLabel } from '@material-ui/core'

import { ReactiveTabs } from './ReactiveTabs'
import { WbScreenTabs } from './WbScreenTabs'
import { MyServerTable } from './MyServerTable'
import { WbOrdersSupplyDropdown } from './WbOrdersSupplyDropdown'
import { PullOrdersWithErrorsButton } from './PullOrdersWithErrorsButton'

import { useLogic, useStore } from '../hooks/storeHook'

import { createWbOrderColumns } from '../types/wbOrderColumns'
import { WbAccountRequiredProps } from '../types/accountProps'
import { OrdersTopPagination } from './OrdersTopPagination'
import search from '../assets/search.svg'
import { useOrderSearchStyles } from '../hooks/ordersSearchStylesHook'
import { BootstrapInput } from './BootstrapInput'

import { WbDeliveryType } from '../server/mpsklad_core/Entity/Wildberries/WbDeliveryType'

export const WbOrdersTabsScreen = () =>
  <WbScreenTabs ScreenComponent={WbOrdersScreen}/>

enum WbOrdersTableTab {
  Active = 0,
  Cancelled = 1,
  WithErrors = 2
}

const tabItems = [{
  name: 'Активные',
  value: WbOrdersTableTab.Active
}, {
  name: 'Отменённые',
  value: WbOrdersTableTab.Cancelled
}, {
  name: 'С ошибками',
  value: WbOrdersTableTab.WithErrors
}]

export const WbOrdersScreen =
  observer(
    ({account}: WbAccountRequiredProps) => {
      const {api, wbOrderLoaders} = useLogic()
      const {orderStore: {createWbOrdersTable}} = useStore()
      const classes = useOrderSearchStyles()

      const wbOrderColumns = useMemo(
        () => createWbOrderColumns(account),
        [account])

      const wbOrders = useMemo(
        () => createWbOrdersTable(account.id),
        [createWbOrdersTable, account])

      useEffect(() => wbOrderLoaders.addHandler(wbOrders.load), [wbOrderLoaders, wbOrders])

      const onTabChange =
        (newTabIndex: number) =>
          wbOrders.reload(() => {
            switch (newTabIndex as WbOrdersTableTab) {
              case WbOrdersTableTab.Active:
                wbOrders.setPropFilter('hasError', JSON.stringify(false))
                return

              case WbOrdersTableTab.Cancelled:
                wbOrders.setPropFilter('isCancelled', JSON.stringify(true))
                return

              case WbOrdersTableTab.WithErrors:
                wbOrders.setPropFilter('hasError', JSON.stringify(true))
                return

              default:
                throw new Error(`Unhandled value of WbOrdersTableTab: ${newTabIndex}`)
            }
          })

      const currentTab =
        wbOrders.findPropFilterValue('isCancelled') === JSON.stringify(true)
        ? WbOrdersTableTab.Cancelled
        : wbOrders.findPropFilterValue('hasError') === JSON.stringify(true)
          ? WbOrdersTableTab.WithErrors
          : WbOrdersTableTab.Active

      const tabTitles = tabItems.map(_ => <span>{_.name}</span>)

      const searchPlaceholder =
        account.deliveryType === WbDeliveryType.FBW
        ? 'Id / Id в Wildberries / Уникальный идентификатор заказа (FBW) / Код размера (chrtId) / Идентификатор вещи / Штрихкод'
        : 'Id / Id в Wildberries / Код размера (chrtId) / Идентификатор вещи / Штрихкод'

      return (
        <ReactiveTabs titles={tabTitles} tab={currentTab} onTabChange={onTabChange}>
          <Box display="flex" justifyContent="space-between" className={classes.container}>
            <Box style={{position: 'relative'}}>
              {
                currentTab === WbOrdersTableTab.Active &&
                <Box marginRight={10}>
                  <WbOrdersSupplyDropdown account={account} wbOrders={wbOrders}/>
                </Box>
              }

              <Box>
                <Box style={{minWidth: 350, maxWidth: 350}}>
                  <FormLabel className={classes.root}>Поиск</FormLabel>

                  <BootstrapInput
                    id="search-wb-orders"
                    onChange={e => wbOrders.setGlobalFilter(e.target.value)}
                    inputProps={{'aria-label': 'search wb orders'}}
                    fullWidth
                  />

                  <FormHelperText className={classes.root}>
                    {searchPlaceholder}
                  </FormHelperText>
                </Box>

                <img className="search-icon" src={search} alt="Поиск" style={{top: 82, right: 20}}/>
              </Box>
            </Box>

            {
              currentTab === WbOrdersTableTab.WithErrors &&
              <Box marginRight={30}>
                <PullOrdersWithErrorsButton
                  ordersTable={wbOrders}
                  pullOrders={() => api.order.pullWbOrdersWithErrors(account.id)}
                />
              </Box>
            }

            <OrdersTopPagination table={wbOrders}/>
          </Box>

          <MyServerTable
            hideTopPagination
            table={wbOrders}
            columns={wbOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />
        </ReactiveTabs>
      )
    })