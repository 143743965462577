import React from 'react'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

import { SyncStateCell } from './SyncStateCell'

import { useStore } from '../hooks/storeHook'

import { makeOzonAccountName, makeWbAccountName, makeYandexMarketAccountName } from '../common/accountUtils'

import { IntegrationTypeKey, SyncAccountInfo } from '../types/integrationTypeUtils'

import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

export const StatusSyncTable =
  observer(() => {
    const {
      syncStore: {ozonAccounts, wbAccounts, yandexMarketAccounts, moySkladAccount},
      allowedIntegrations
    } = useStore()

    const syncAccountsInfo: SyncAccountInfo<IntegrationTypeKey>[] = []

    const classes = useStyles()

    if (moySkladAccount) {
      syncAccountsInfo.push({
        type: 'MoySklad',
        account: moySkladAccount,
        displayName: 'МойСклад'
      })
    }

    if (allowedIntegrations[IntegrationType.Ozon]) {
      syncAccountsInfo.push(
        ...ozonAccounts.map(_ => ({
          type: 'Ozon' as const,
          account: _,
          displayName: makeOzonAccountName(_)
        })))
    }

    if (allowedIntegrations[IntegrationType.Wildberries]) {
      syncAccountsInfo.push(
        ...wbAccounts.map(_ => ({
          type: 'Wildberries' as const,
          account: _,
          displayName: makeWbAccountName(_)
        })))
    }

    if (allowedIntegrations[IntegrationType.YandexMarket]) {
      syncAccountsInfo.push(
        ...yandexMarketAccounts.map(_ => ({
          type: 'YandexMarket' as const,
          account: _,
          displayName: makeYandexMarketAccountName(_)
        })))
    }

    if (!syncAccountsInfo.length) {
      return <Box padding={3}>Аккаунты не подключены</Box>
    }

    const showStocks =
      moySkladAccount != null
      || ozonAccounts.some(_ => _.isDeliveryFromSellerWarehouse)
      || wbAccounts.some(_ => _.isDeliveryFromSellerWarehouse)
      || yandexMarketAccounts.some(_ => _.isDeliveryFromSellerWarehouse)

    return (
      <TableContainer style={{maxWidth: 'calc(100vw - 330px)'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.emptyCell}>
                {/* spaaaaaace */}
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <TableCell className={classes.headCell} key={makeKey(info)}>
                      {info.displayName}
                    </TableCell>)
              }
            </TableRow>
          </TableHead>

          <TableBody>

            <TableRow>
              <TableCell className={classes.statusLabelCell}>
                Получение товаров
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <SyncStateCell
                      key={makeKey(info)}
                      type={info.type}
                      state={info.account.syncStates.pullProductsState}
                    />)
              }
            </TableRow>

            {
              showStocks &&
              <TableRow>
                <TableCell className={classes.statusLabelCell}>
                  Получение остатков
                </TableCell>

                {
                  syncAccountsInfo.map(
                    info =>
                      <SyncStateCell
                        key={makeKey(info)}
                        type={info.type}
                        state={info.account.syncStates.pullStocksState}
                      />)
                }
              </TableRow>
            }

            {
              showStocks &&
              <TableRow>
                <TableCell className={classes.statusLabelCell}>
                  Отправка остатков
                </TableCell>

                {
                  syncAccountsInfo.map(
                    info =>
                      <SyncStateCell
                        key={makeKey(info)}
                        type={info.type}
                        state={info.account.syncStates.pushStocksState}
                      />)
                }
              </TableRow>
            }

            <TableRow>
              <TableCell className={classes.statusLabelCell}>
                Получение заказов
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <SyncStateCell
                      key={makeKey(info)}
                      type={info.type}
                      state={info.account.syncStates.pullOrdersState}
                    />)
              }
            </TableRow>

            <TableRow>
              <TableCell className={classes.statusLabelCell}>
                Отправка заказов
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <SyncStateCell
                      key={makeKey(info)}
                      type={info.type}
                      state={info.account.syncStates.pushOrdersState}
                    />)
              }
            </TableRow>

            <TableRow>
              <TableCell className={classes.statusLabelCell}>
                Отправка отчётов о реализации
              </TableCell>

              {
                syncAccountsInfo.map(
                  info =>
                    <SyncStateCell
                      key={makeKey(info)}
                      type={info.type}
                      state={info.account.syncStates.pushRealizationReportsState}
                    />)
              }
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  })

const makeKey =
  ({type, account}: SyncAccountInfo<IntegrationTypeKey>) =>
    `${type}_${account.id}`

const useStyles = makeStyles(() => ({
  headCell: {
    backgroundColor: '#AED8FF',
    color: '#4A7DAC',
    fontSize: 12,
    borderRight: '2px solid #FFF'
  },
  emptyCell: {
    border: '1px solid #E7F4FF'
  },
  statusLabelCell: {
    borderRight: '1px solid #FFF',
    borderLeft: '1px solid #E7F4FF',
    borderBottom: '1px solid #FFF',
    borderTop: '1px solid #FFF',
    backgroundColor: '#E7F4FF',
    color: '#3987CF',
    fontWeight: 'bold',
    minWidth: '250px !important',
    height: 30,
    fontSize: '11px !important'
  }
}))